import React from "react"
import { Pagination, Button, message } from "antd"
import { navigate } from "gatsby"

import { getThemeListAsUser } from "../../api/theme"
import Layout from "../../layouts"
import ThemeCard from "../../components/ThemeCard"
import {
  USER_THEME_TYPE_DESIGN,
  USER_THEME_TYPE_BUY,
  USER_THEME_TYPE_SUBSCRIBE,
} from "../../utils/constant"
import "./manage.css"

// 用于骨架屏
const pageSize = 4
const subscribeTemplate = new Array(pageSize).fill({}).map((_, index) => ({
  userThemeType: 0,
}))
const buyTemplate = new Array(pageSize).fill({}).map((_, index) => ({
  userThemeType: 1,
}))
const designTemplate = new Array(pageSize).fill({}).map((_, index) => ({
  userThemeType: 2,
}))
const template = subscribeTemplate.concat(designTemplate).concat(buyTemplate)

class ThemeList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      themes: template,
      loading: true,
    }
  }

  componentWillMount() {
    this.initThemeList()
  }

  initThemeList = async () => {
    const res = await getThemeListAsUser()
    if (res) {
      this.setState({ themes: res.data.data, loading: false })
    } else {
      message.error("加载全部职位界面出错")
    }
  }

  handleAddNewTheme = () => {
    navigate("/themes/upload")
  }

  render() {
    const { themes, loading } = this.state

    const subscribeThemeList = themes.filter(
      theme => theme.userThemeType === USER_THEME_TYPE_SUBSCRIBE
    )
    const buyThemeList = themes.filter(
      theme => theme.userThemeType === USER_THEME_TYPE_BUY
    )
    const designThemeList = themes.filter(
      theme => theme.userThemeType === USER_THEME_TYPE_DESIGN
    )
    return (
      <Layout isSpacerDisplay={true} title="管理全部职位">
        <div className="mdnice-theme-container">
          <p className="mdnice-theme-manage-title">已订阅的全部职位</p>
          <div className="mdnice-theme-list">
            {subscribeThemeList.map((item, index) => (
              <ThemeCard key={index} {...item} loading={loading} />
            ))}
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
          </div>

          <p className="mdnice-theme-manage-title">我设计的全部职位</p>
          <div className="mdnice-theme-list">
            {designThemeList.map((item, index) => (
              <ThemeCard key={index} {...item} loading={loading} />
            ))}
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
          </div>

          <p className="mdnice-theme-manage-title">已购买的全部职位</p>
          <div className="mdnice-theme-list">
            {buyThemeList.map((item, index) => (
              <ThemeCard key={index} {...item} loading={loading} />
            ))}
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
            <div style={{ width: 250, margin: "0em 1em" }} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ThemeList
